<template>
    <div id="companyDetail">
        <div class="companyDetail-main">
            <div class="companyDetail-header" v-if="activityImages != null">
                <img alt="" :src=activityImages style="width: 100%;height: 100%"/>
            </div>
            <div class="companyDetail-head">
                <div class="companyDetail-card-head">
                    <div class="companyDetail-card-headWord">
                        <div class="companyDetail-card-headWord-name" >
                            <span>{{postInfo.post}}</span>
<!--                            <i><span>{{postInfo.salary}}</span></i>-->
                        </div>
                        <div class="companyDetail-card-headWord-content">
                            <span class="companyDetail-card-headWord-contentDetail">{{postInfo.workPlace}}</span>
                            <span class="companyDetail-card-headWord-contentDetail">{{postInfo.edu}}</span>
                            <span class="companyDetail-card-headWord-contentDetail">{{postInfo.people}}人</span>
                            <span class="companyDetail-card-headWord-contentDetails">全职</span>
                        </div>
                    </div>
<!--                    <div class="post-btn-bar">-->
<!--                        <div class="post-btn-bars" @click="handleDialogVisible">投递简历</div>-->
<!--                    </div>-->
                    <Dialog :dialogVisible = 'dialogVisible' :activityName = 'activityName' :companyName = 'companyName' :postName = 'postName' @sendDialogVisible = 'getDialogVisible'></Dialog>
                </div>
            </div>
            <div class="companyDetail-introduce">
                <div class="companyDetail-introduce-left">
                    <div class="companyDetail-introduce-left-company">
                        <div class="companyDetail-introduce-left-companyTitle">
                            <h3>薪资待遇</h3>
                            <div class="companyDetail-introduce-left-companyText">
                                <div class="companyDetail-introduce-left-companyText-text companyDetail-introduce-left-companyText-ellipsis">
                                    <p>{{postInfo.salary}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="companyDetail-introduce-left-company">
                        <div class="companyDetail-introduce-left-companyTitle">
                            <h3>专业要求</h3>
                            <div class="companyDetail-introduce-left-companyText">
                                <div class="companyDetail-introduce-left-companyText-text companyDetail-introduce-left-companyText-ellipsis">
                                    <p>{{postInfo.majorAsk}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="companyDetail-introduce-left-company">
                        <div class="companyDetail-introduce-left-companyTitle">
                            <h3>任职要求</h3>
                            <div class="companyDetail-introduce-left-companyText">
                                <div class="companyDetail-introduce-left-companyText-text companyDetail-introduce-left-companyText-ellipsis">
                                    <p>{{postInfo.postAsk}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="companyDetail-introduce-left-company">
                        <div class="companyDetail-introduce-left-companyTitle">
                            <h3>岗位职责</h3>
                            <div class="companyDetail-introduce-left-companyText">
                                <div class="companyDetail-introduce-left-companyText-text companyDetail-introduce-left-companyText-ellipsis">
                                    <p>{{postInfo.postDuty}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <div class="company-info">
                        <router-link :to="{name:'companyDetail',query:{activityId:this.activityId,tab:'tab2',id:companyInfo.id}}">
                          <img class="company-info-img" src="https://sxzph.zhaopin.com/_nuxt/img/company.086631c.png" alt=""/>
                          <h3 class="jhover">{{companyInfo.name}}</h3>
                        </router-link>
                        <div class="content-list">
                            <div class="item">
                                <span>{{companyInfo.city}}</span>
<!--                                <span>500-999人</span>-->
<!--                                <span>民营</span>-->
                            </div>
                        </div>
                        <div class="company-introduce">
                            <p>{{companyInfo.introduction}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="companyDetail-footer">
            </div>

        </div>
    </div>

</template>

<script>
    import Dialog from '@/views/dialog'
    import * as api from '@/api/api'
    export default {
        name: "post",
        components: {
            Dialog
        },
        data() {
            return {
                activityId:this.$route.query.activityId,
                activity: [],
                activityImages:'',
                postId:this.$route.query.postId,
                companyId:this.$route.query.companyId,
                postInfo:'',
                companyInfo:'',
                dialogVisible:false,
                activityName:'',
                companyName:'',
                postName:''
            }
        },
        created() {
            this.findActAndCompById()
            this.getPostInfo()
            this.findCompanyInfo()
        },
        methods: {
            //查询活动信息
            findActAndCompById() {
                if (this.activityId != null){
                    api.findActAndComByAct({id:this.activityId}).then(res => {
                        this.activity = res
                        this.activityName = res.name
                        this.activityImages = res.activityImages
                    })
                }else {
                    this.activityImages = null
                    api.findHeadHuntingAndComByAct({id:1}).then(res => {
                        this.activityName = res.name
                    })

                }
            },
            //获取岗位信息通过id
            getPostInfo() {
                api.findPostById({id:this.postId}).then(res => {
                    this.postInfo = res
                    this.postName = res.post
                })
            },
            //查询单位信息
            findCompanyInfo() {
                api.findComAndPostByCom({id:this.companyId}).then(res => {
                    this.companyInfo = res
                    this.companyName = res.name
                })
            },
            handleDialogVisible() {
                this.dialogVisible = !this.dialogVisible
            },
            getDialogVisible(e) {
                this.dialogVisible = e
            }
        }
    }
</script>

<style scoped>
    @media screen and (min-width: 991px){
        #companyDetail {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
        .companyDetail-main {
            width: 100%;
            margin: 0 auto 16px;
            padding-bottom: 120px;
        }
        .companyDetail-header {
            width: 100%;
            height: 70vh;
        }
        .companyDetail-head {
            width: 100%;
            background: #fff;
            margin: 0 auto;
            border-top: #F5F5F5 2px solid;
        }
        .companyDetail-card-head {
            width: 64%;
            margin: 0 auto;
            height: 126px;
            -webkit-box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .act-section-left span {
            background: url('../../assets/images/rightArrow.png') 0 no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            margin: 42px auto;
        }

        .companyDetail-card-headWord {
            width: 898px;
            padding-right: 20px;
            position: relative;
        }
        .companyDetail-card-headWord-name {
            height: 32px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            /*color: #141933;*/
            line-height: 32px;
            color: #fb6160;
        }
        .companyDetail-card-headWord-name i span {
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fb6160;
        }
        .companyDetail-card-headWord-name i{
            margin-left: 24px;
        }
        .companyDetail-card-headWord-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            margin-top: 12px;
        }
        .companyDetail-card-headWord-contentDetail {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headWord-contentDetail:after {
            display: inline-block;
            content: "";
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
            -webkit-box-align: center;
        }
        .companyDetail-card-headWord-contentDetails {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headImg {
            width: 78px;
            height: 78px;
            border-radius: 4px;
            margin-right: 20px;
        }
        .post-btn-bar {

        }
        .post-btn-bars {
            width: 120px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #587cf7;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fff;
        }
        .companyDetail-introduce {
            width: 64%;
            margin: 0 auto;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
        }
        .companyDetail-introduce-left {
            width: 864px;
        }
        .content-right {
            margin-top: 16px;
            width: 320px;
            height: 100%;
        }


        .companyDetail-introduce-left-company {
            margin-top: 16px;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
            padding: 20px 40px 40px;
        }
        .companyDetail-introduce-left-companyTitle {

        }
        .companyDetail-introduce-left-companyTitle h3 {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 22px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .companyDetail-introduce-left-companyTitle h3:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 16px;
            background: #141933;
            border-radius: 0 2px 2px 0;
            margin-right: 8px;
        }
        .companyDetail-introduce-left-companyText {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .companyDetail-introduce-left-companyText-text {
            margin-top: 24px;
            text-align: justify;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 24px;
            word-break: break-all;
        }
        .companyDetail-introduce-left-companyText-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /*-webkit-line-clamp: 6;*/
            /*line-clamp: 6;*/
            -webkit-box-orient: vertical;
        }
        .company-info {
            padding: 20px 40px 40px;
            width: 320px;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
        }
        .company-info-img {
            height: 72px;
            border-radius: 4px;
            margin-bottom: 24px;
            display: block;
            -o-object-fit: cover;
            object-fit: cover;
            cursor: pointer;
        }
        .jhover {
            cursor: pointer;
        }
        .company-info h3{
            width: 100%;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 22px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .content-list {
            margin-top: 16px;
        }
        .item {
            margin-bottom: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
        }
        .item span {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 20px;
            display: inline-block;
            margin-bottom:8px;
        }
        .company-introduce {
            margin-top: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            line-clamp: 6;
            -webkit-box-orient: vertical;
            word-break: break-all;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 24px;
        }
    }
/*    小设备*/
    @media screen and (max-width: 991px){
        #companyDetail {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
        .companyDetail-main {
            width: 100%;
            margin: 0 auto 16px;
            padding-bottom: 60px;
        }
        .companyDetail-head {
            width: 100%;
            background: #fff;
        }
        .companyDetail-card-head {
            width: 100%;
            margin: 0 auto;
            padding: 10px 0 0 0;
            height: 128px;
            -webkit-box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            /*align-items: center;*/
            flex-direction: column;
        }
        .act-section-left span {
            background: url('../../assets/images/rightArrow.png') 0 no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            margin: 42px auto;
        }

        .companyDetail-card-headWord {
            position: relative;
        }
        .companyDetail-card-headWord-name {
            height: 32px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            /*color: #141933;*/
            line-height: 32px;
            color: #fb6160;
            padding: 0 20px;
        }
        .companyDetail-card-headWord-name i span {
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fb6160;
        }
        .companyDetail-card-headWord-name i{
            margin-left: 24px;
        }
        .companyDetail-card-headWord-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            margin-top: 45px;
            padding: 0 20px;
        }
        .companyDetail-card-headWord-contentDetail {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headWord-contentDetail:after {
            display: inline-block;
            content: "";
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
            -webkit-box-align: center;
        }
        .companyDetail-card-headWord-contentDetails {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headImg {
            width: 78px;
            height: 78px;
            border-radius: 4px;
            margin-right: 20px;
        }
        .post-btn-bar {
            width: 100%;
            display: flex;
            justify-content: center;
            position: fixed;
            bottom: 0;
            background: white;
            padding: 10px 0;
        }
        .post-btn-bars {
            width: 240px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #587cf7;
            border-radius: 40px;
            cursor: pointer;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #fff;
        }
        /*.companyDetail-introduce {*/
        /*    width: 100%;*/
        /*    margin: 0 auto;*/
        /*    height: 100%;*/
        /*    display: -webkit-box;*/
        /*    display: -ms-flexbox;*/
        /*    display: flex;*/
        /*    -webkit-box-orient: horizontal;*/
        /*    -webkit-box-direction: normal;*/
        /*    -ms-flex-direction: row;*/
        /*    flex-direction: row;*/
        /*    -webkit-box-pack: justify;*/
        /*    -ms-flex-pack: justify;*/
        /*    justify-content: space-between;*/
        /*    -webkit-box-align: start;*/
        /*    -ms-flex-align: start;*/
        /*    align-items: flex-start;*/
        /*}*/
        /*.companyDetail-introduce-left {*/
        /*    width: 864px;*/
        /*}*/
        .content-right {
            margin-top: 16px;
            width: 100%;
            height: 100%;
        }


        .companyDetail-introduce-left-company {
            margin-top: 16px;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
            padding: 20px 40px 40px;
        }
        .companyDetail-introduce-left-companyTitle {

        }
        .companyDetail-introduce-left-companyTitle h3 {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 22px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .companyDetail-introduce-left-companyTitle h3:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 16px;
            background: #141933;
            border-radius: 0 2px 2px 0;
            margin-right: 8px;
        }
        .companyDetail-introduce-left-companyText {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .companyDetail-introduce-left-companyText-text {
            margin-top: 24px;
            text-align: justify;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 24px;
            word-break: break-all;
        }
        .companyDetail-introduce-left-companyText-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /*-webkit-line-clamp: 6;*/
            /*line-clamp: 6;*/
            -webkit-box-orient: vertical;
        }
        .company-info {
            padding: 20px 40px 40px;
            width: 100%;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
        }
        .company-info-img {
            height: 72px;
            border-radius: 4px;
            margin-bottom: 24px;
            display: block;
            -o-object-fit: cover;
            object-fit: cover;
            cursor: pointer;
        }
        .jhover {
            cursor: pointer;
        }
        .company-info h3{
            width: 100%;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 22px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .content-list {
            margin-top: 16px;
        }
        .item {
            margin-bottom: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
        }
        .item span {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 20px;
            display: inline-block;
            margin-bottom:8px;
        }
        .company-introduce {
            margin-top: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            line-clamp: 6;
            -webkit-box-orient: vertical;
            word-break: break-all;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 24px;
        }
    }
</style>
