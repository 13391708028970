<template>
    <div class="company-container">
<!--        <div v-if="activity.id" class="company-main company-empty">-->
<!--            <el-empty description="暂未开放 敬请期待哦~"></el-empty>-->
<!--        </div>-->
<!--     省市总览   -->
        <div v-if="activityId == 19" class="company-main">
            <div class="company-header">
                <img alt="" src="https://www.ccqjob.com/files/imgs/2024GCHyouer11.jpg" style="width: 100%;height: 100%"/>
            </div>
            <div style="display: flex;justify-content: center;">
                <div class="gradient-rectangle">{{content}}</div>
            </div>
            <div class="company-body">
                <div class="company-body-count">
                    <div class="company-body-count-company">
                        <p class="company-countText">447</p>
                        <p class="company-wordText">参与单位</p>
                    </div>
                    <div class="company-body-count-post">
                        <p class="company-countText">2147</p>
                        <p class="company-wordText">招聘岗位</p>
                    </div>
                </div>
                <div class="company-bodyCard" style="width: 88%">
                    <div class="company-bodyCard-count1" style="margin-bottom: 25px">
                        <div class="company-bodyCard-count2">浏览量</div>
                        <div class="company-bodyCard-count3">{{ NationalTalentPageView + pageViews }}</div>
                    </div>
                    <div class="company-body-card" style="justify-content: center;">
                        <div class="company-body-cardDetail" v-for="(item,index) in imgUrlList" :key="index" >
                           <div class="box-card" >
                               <router-link :to="{name:'company',query:{activityId:item.activityId}}" class="company-card-head" >
                                   <div class="image-container">
                                       <img :src="item.imgUrl"  alt=""/>
                                   </div>
                               </router-link>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="company-footer"></div>
        </div>
<!--    重庆专区-->
        <div v-else-if="activityId == 39" class="company-main">
            <div class="company-header">
                <img alt="" src="https://www.ccqjob.com/files/imgs/2024GCHCQ.jpg" style="width: 100%;height: 100%"/>
            </div>
            <div style="display: flex;justify-content: center;height: 40px" />
            <div class="company-body">
                <div class="company-body-count">
                    <div class="company-body-count-company">
                        <p class="company-countText">306</p>
                        <p class="company-wordText">参与单位</p>
                    </div>
                    <div class="company-body-count-post">
                        <p class="company-countText">1207</p>
                        <p class="company-wordText">招聘岗位</p>
                    </div>
                </div>
                <div class="company-bodyCard" style="width: 88%">
                    <div class="company-bodyCard-count1" style="margin-bottom: 25px">
                        <div class="company-bodyCard-count2">浏览量</div>
                        <div class="company-bodyCard-count3">{{ 2386 + pageViews}}</div>
                    </div>
                    <div class="company-body-card" style="justify-content: center">
                        <div class="company-body-cardDetail" v-for="(item,index) in imgUrlList2" :key="index" >
                            <div class="box-card" >
                                <router-link :to="{name:'company',query:{activityId:item.activityId}}" class="company-card-head" >
                                    <div class="image-container">
                                        <img :src="item.imgUrl"  alt=""/>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="company-footer"></div>
        </div>
<!--    常规单位岗位-->
        <div v-else class="company-main"
             v-loading="loading"
             element-loading-text="拼命加载中，请耐心等待~"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <div class="company-header">
                <img alt="" :src="activity.activityImages" style="width: 100%;height: 100%"/>
            </div>

            <div class="company-body" v-if="companys.length>0">
<!--                <div>详情页面</div>-->
                <div class="company-body-count">
                    <div class="company-body-count-company">
                        <p class="company-countText">{{companyNum}}</p>
                        <p class="company-wordText">参与单位</p>
                    </div>
                    <div class="company-body-count-post">
                        <p class="company-countText">{{postNum}}</p>
                        <p class="company-wordText">招聘岗位</p>
                    </div>
                </div>
                <div class="company-bodyCard">
                    <div class="company-bodyCard-count1">
                        <div class="company-bodyCard-count2">浏览量</div>
                        <div class="company-bodyCard-count3">{{ count + pageViews }}</div>
                    </div>
                    <div class="company-body-tab">单位大厅</div>
                    <div class="company-body-card">
                        <div class="company-body-cardDetail" v-for="company in companys">
                            <el-card class="box-card" :body-style="{padding: '0 20px'}">
                                <div slot="header" class="clearfix">
                                    <router-link :to="{name:'companyDetail',query:{activityId:activity.id,id:company.id,tab:'tab1'}}" class="company-card-head">
                                        <div class="company-card-headWord">
                                            <div class="company-card-headWord-name">{{company.name}}</div>
                                            <div class="company-card-headWord-content">
                                                <span class="company-card-headWord-contentDetail">{{company.city}}</span>
<!--                                                <span class="company-card-headWord-contentDetail">500-999人</span>-->
                                                <span class="company-card-headWord-contentDetails">{{company.companyType}}</span>
                                            </div>
                                        </div>
<!--                                        <div class="company-card-headImg">-->
<!--                                            <img :src="company.images" style="width: 100%;height: 100%" alt=""/>-->
<!--                                        </div>-->
                                    </router-link>
                                </div>
                                <div class="company-card-bodyPost">
                                    <div class="company-card-bodyPostItem"  v-for="post in company.postList">
                                        <router-link :to="{name:'post',query: {activityId:activity.id,postId:post.id,companyId:company.id}}" class="card-job-name">
                                            <span class="job_name">{{post.post}}</span>
                                            <span class="job_salary">{{post.salary}}</span>
                                        </router-link>
                                        <div class="act-section-left">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-deal">
                                    <router-link :to="{name:'companyDetail',query:{activityId:activity.id,id:company.id,tab:'tab1'}}">
                                    <span class="card-deal-more">更多职位
                                        <i class="el-icon-arrow-right" />
                                    </span>
                                    </router-link>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="company-empty">
                <el-empty description="暂无数据~"></el-empty>
            </div>
            <div class="company-footer"></div>
        </div>
    </div>
</template>

<script>
    import * as api from '@/api/api'
    import Dialog from '@/views/dialog'
    import {computed} from "vue";
    import {getNationalTalentPageView} from "@/api/api";
    export default {
        name: "index",
        data() {
            return {
                NationalTalentPageView: 0,
                count: 0,
                pageViews: this.getPageViewsFromStorage() || 0,
                activityId: this.$route.query.activityId || "",
                activity: {},
                companys:[],
                companyId:[],
                dialogVisible: false,
                companyNum:'',
                postNum:'',
                loading: true,
                imgUrlList: [
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/nmg.png",
                        activityId: 20
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/gxs.png",
                        activityId: 21
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/cqs.png",
                        activityId: 39
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/scs.png",
                        activityId: 22
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/gzs.png",
                        activityId: 23
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/yns.png",
                        activityId: 24
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/xz.png",
                        activityId: 25
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/sxs.png",
                        activityId: 26
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/gss.png",
                        activityId: 27
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/qhs.png",
                        activityId: 28
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/nxs.png",
                        activityId: 29
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/xj.png",
                        activityId: 30
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/xjs.png",
                        activityId: 31
                    },
                ],
                imgUrlList2: [
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/2024youer3.png",
                        activityId: 32
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/2024gx.png",
                        activityId: 33
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/2024gq.png",
                        activityId: 34
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/2024sydw.png",
                        activityId: 35
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/2024kyys.png",
                        activityId: 36
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/2024myqy.png",
                        activityId: 37
                    },
                    {
                        imgUrl: "https://www.ccqjob.com/files/imgs/2024mtx.png",
                        activityId: 38
                    },
                ],
                content: "重庆国际人才交流大会西部地区联合引才活动将于2024年11月23日在中国·重庆人力资源服务产业园南区2楼举办。此次活动将组织500家市内外有招聘需求的企事业单位及市内高校毕业生、有求职需求的重点产业紧缺人才参加。"
            }
        },
        async created() {
            await this.findActAndCompById()
            this.getPost()
            this.incrementPageViews();
            this.getNationalTalentPageView()
        },
        watch: {
            '$route.query.activityId'(newVal) {
                //this.activityId = newVal;
                location.reload()
            },
            deep: true, // 深度观察
            immediate: true // 立即执行
        },
        methods: {
            getNationalTalentPageView() {
                api.getNationalTalentPageView().then(res => {
                    console.log(res,"getNationalTalentPageView")
                    res.forEach(item => {
                        console.log(item.companyNum,"item")
                        this.NationalTalentPageView = item.companyNum + this.NationalTalentPageView
                    })
                })
                console.log(this.NationalTalentPageView,"this.NationalTalentPageView")
            },
            incrementPageViews() {
                this.pageViews++;
                // 将新的浏览量保存到localStorage
                this.savePageViewsToStorage(this.pageViews);
            },
            getPageViewsFromStorage() {
                // 从localStorage获取浏览量
                return parseInt(localStorage.getItem('pageViews')) || 0;
            },
            savePageViewsToStorage(pageViews) {
                // 将浏览量保存到localStorage
                localStorage.setItem('pageViews', pageViews.toString());
            },

            //查询活动信息
            async findActAndCompById() {
                try {
                    let res = await api.findActAndComByAct({id:this.activityId})
                    if(res) {
                        console.log(res,"res")
                        this.activity = res
                        console.log(this.activity,"this.activity")
                        this.companyNum = res.companyList.length
                        this.count = res.companyNum
                        setTimeout(() => {
                            this.loading = false
                        },2000)
                }
                } catch {
                    this.loading = false
                }

            },

            //查询岗位信息
            getPost() {
                api.getPostByComId({id:this.activityId}).then(res => {
                    this.companys = res
                    let postNum = 0
                    for(let i = 0; i < res.length; i++) {
                        let num = res[i].postList.length
                        postNum += num
                    }
                    this.postNum = postNum
                })
            },
            handleDialogVisible() {
                this.dialogVisible = true
            }
        },
    }
</script>

<style scoped>
@media screen and (min-width: 991px){
    ::v-deep .el-dialog {
        position: relative;
        margin: 0 auto;
        background: #FFF;
        border-radius: 2px;
        box-shadow: 0 1px 1px white;
        box-sizing: border-box;
        width: 30vw;
    }

    ::v-deep .el-empty__description p {
        margin: 0;
        font-size: 20px;
        color: #909399;
        font-weight: bold;
    }

    .image-container {
        width: 100%;
        height: 100%; /* 或者你想要的任何高度 */
    }

    .image-container img {
        width: 100%;
    }
    .gradient-rectangle {
        margin: 80px 0;
        width: 95vw; /* 根据需要调整宽度 */
        padding: 20px 40px;
        color: white;
        background: linear-gradient(to right, #0C5BAD, #267DC5); /* 使用深蓝（#001f3f）到浅蓝（#87ceeb）的渐变 */
        border: none; /* 确保没有边框 */
        box-shadow: none; /* 确保没有阴影 */
        border-radius: 20px;
        font-size: 3rem;
        text-indent: 2em;
    }
    .company-empty {
        width: 100%;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .company-container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    .company-main {
        padding-bottom: 80px;
    }
    .company-header {
        width: 100%;
        height: 70vh;
    }
    .company-body-count {
        display: flex;
        background: white;
        width: 100%;
        height: 86px;
        justify-content: center;
        align-items: center;
    }
    .company-body-count-company {
        width: 30%;
        text-align: center;
    }
    .company-body-count-post {
        width: 30%;
        text-align: center;
    }
    .company-countText {
        height: 38px;
        font-size: 32px;
        font-weight: 500;
        color: #fb6160;
        line-height: 38px;
    }
    .company-wordText {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        color: #7a7f99;
        font-weight: 400;
        line-height: 22px;
        height: 22px;
        margin-top: 2px;
    }
    .company-body-tab {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #141933;
        margin: 40px 0;
    }
    .act-section-left span {
        background: url('../../assets/images/rightArrow.png') 0 no-repeat;
        display: block;
        width: 24px;
        height: 24px;
        margin: 42px auto;
    }
    .company-bodyCard {
        width: 64%;
        margin: 32px auto 16px auto;
    }
    /*卡片*/
    .company-body-card {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .company-body-cardDetail {
        padding: 15px 25px;
        height: auto;
        overflow: hidden;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 0 10px 0;
    }
    .company-card-headImg {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
    }
    .company-card-head {
        height: 40px;
        background: #fff;
        border-radius: 4px 4px 0 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    .company-card-headWord-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 18px;
    }
    .company-card-headWord-contentDetail {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 18px;
    }
    .company-card-headWord-contentDetails {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 18px;
    }
    .company-card-headWord-contentDetail:after {
        display: inline-block;
        content: "";
        width: 1px;
        height: 10px;
        background: #e6e8f2;
        border-radius: 1px;
        margin-left: 8px;
        margin-right: 8px;
        -webkit-box-align: center;
    }
    .company-card-headWord-name {
        cursor: pointer;
        letter-spacing: 0;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141933;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 297px;
    }
    .company-card-bodyPost {
        height: 114px;
        margin-top: 16px;
        overflow: hidden;
    }
    .company-card-bodyPostItem {
        height: 26px;
        margin-bottom: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .card-job-name {
        cursor: pointer;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #333;
        line-height: 14px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .job_name {
        display: inline-block;
        max-width: 225px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .job_salary {
        margin-left: 8px;
        display: inline-block;
        max-width: 200px;
        /*72px*/
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .delivery-work {
        text-align: center;
        width: 56px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        cursor: pointer;
        background: #f8f9fc;
        border-radius: 12px;
        border: 1px solid #e6e8f2;
        color: #b8bbcc;
        background: #fff;
        border: 1px solid #587cf7;
        color: #587cf7;
    }
    .card-deal {
        overflow: hidden;
        /*bottom: 12px;*/
        position: relative;
        float: right;
    }
    .card-deal-more {
        cursor: pointer;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 30px;

    }
    .el-icon-arrow-right {
        font-family: element-icons !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
    }
    /*<!--    卡片属性-->*/
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }
    .box-card {
        width: 340px;
        height: 248px;
    }
    .company-bodyCard-count1 {
        display: flex;
        flex-direction: column;
        margin: 0 0.2rem 0 0;
    }
    .company-bodyCard-count2 {
        width: 16rem;
        height: 4rem;
        background: #1c99ef;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
    }
    .company-bodyCard-count3 {
        width: 16rem;
        height: 4rem;
        background: #f0f0f0;
        color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
    }
}



/*手机*/
@media screen and (max-width: 991px){
    ::v-deep .el-dialog {
        position: relative;
        margin: 0 auto;
        background: #FFF;
        border-radius: 2px;
        box-shadow: 0 1px 1px white;
        box-sizing: border-box;
        width: 30vw;
    }

    ::v-deep .el-empty__description p {
        margin: 0;
        font-size: 20px;
        color: #909399;
        font-weight: bold;
    }

    .image-container {
        width: 100%;
        height: 100%; /* 或者你想要的任何高度 */
    }

    .image-container img {
        width: 100%;
    }
    .gradient-rectangle {
        margin: 20px 0;
        width: 95vw; /* 根据需要调整宽度 */
        padding: 10px 20px;
        color: white;
        background: linear-gradient(to right, #0C5BAD, #267DC5); /* 使用深蓝（#001f3f）到浅蓝（#87ceeb）的渐变 */
        border: none; /* 确保没有边框 */
        box-shadow: none; /* 确保没有阴影 */
        border-radius: 20px;
        font-size: 1.5rem;
        text-indent: 2em;
    }
    .company-empty {
        width: 100%;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .company-container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    .company-main {
        padding-bottom: 80px;
    }
    .company-header {
        width: 100%;
        //height: 70vh;
    }
    .company-body-count {
        display: flex;
        background: white;
        width: 100%;
        height: 86px;
        justify-content: center;
        align-items: center;
    }
    .company-body-count-company {
        width: 30%;
        text-align: center;
    }
    .company-body-count-post {
        width: 30%;
        text-align: center;
    }
    .company-countText {
        height: 38px;
        font-size: 32px;
        font-weight: 500;
        color: #fb6160;
        line-height: 38px;
    }
    .company-wordText {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        color: #7a7f99;
        font-weight: 400;
        line-height: 22px;
        height: 22px;
        margin-top: 2px;
    }
    .company-body-tab {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #141933;
        margin: 40px 0;
    }
    .act-section-left span {
        background: url('../../assets/images/rightArrow.png') 0 no-repeat;
        display: block;
        width: 24px;
        height: 24px;
        margin: 42px auto;
    }
    .company-bodyCard {
        width: 88%;
        margin: 32px auto 16px auto;
    }
    /*卡片*/
    .company-body-card {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .company-body-cardDetail {
        height: auto;
        overflow: hidden;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 0 10px 0;
    }
    .company-card-headImg {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
    }
    .company-card-head {
        height: 40px;
        background: #fff;
        border-radius: 4px 4px 0 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    .company-card-headWord-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 18px;
    }
    .company-card-headWord-contentDetail {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 18px;
    }
    .company-card-headWord-contentDetails {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 18px;
    }
    .company-card-headWord-contentDetail:after {
        display: inline-block;
        content: "";
        width: 1px;
        height: 10px;
        background: #e6e8f2;
        border-radius: 1px;
        margin-left: 8px;
        margin-right: 8px;
        -webkit-box-align: center;
    }
    .company-card-headWord-name {
        cursor: pointer;
        letter-spacing: 0;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141933;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 297px;
    }
    .company-card-bodyPost {
        height: 114px;
        margin-top: 16px;
        overflow: hidden;
    }
    .company-card-bodyPostItem {
        height: 26px;
        margin-bottom: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .card-job-name {
        cursor: pointer;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #333;
        line-height: 14px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .job_name {
        display: inline-block;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .job_salary {
        margin-left: 8px;
        display: inline-block;
        max-width: 200px;
        /*72px*/
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .delivery-work {
        text-align: center;
        width: 56px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        cursor: pointer;
        background: #f8f9fc;
        border-radius: 12px;
        border: 1px solid #e6e8f2;
        color: #b8bbcc;
        background: #fff;
        border: 1px solid #587cf7;
        color: #587cf7;
    }
    .card-deal {
        overflow: hidden;
        /*bottom: 12px;*/
        position: relative;
        float: right;
    }
    .card-deal-more {
        cursor: pointer;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7a7f99;
        line-height: 30px;

    }
    .el-icon-arrow-right {
        font-family: element-icons !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
    }
    /*<!--    卡片属性-->*/
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }
    .box-card {
        width: 389px;
        height: 248px;
    }
    .company-bodyCard-count1 {
        display: flex;
        flex-direction: column;
        margin: 0 0.2rem 0 0;
    }
    .company-bodyCard-count2 {
        width: 16rem;
        height: 4rem;
        background: #1c99ef;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
    }
    .company-bodyCard-count3 {
        width: 16rem;
        height: 4rem;
        background: #f0f0f0;
        color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
    }
}

</style>
